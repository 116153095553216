import animateScrollTo from 'animated-scroll-to';

export default {
  init() {
    const header = document.querySelector('.js-header');
    const toTop = document.querySelector('.js-to-top');
    const drawerToggler = document.querySelector('.js-drawer-toggler');
    const scrollDown = document.querySelector('.js-scroll-down');
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      const currentScrollPos = window.pageYOffset;
      if (
        prevScrollpos > currentScrollPos &&
        (document.body.scrollTop > 150 ||
          document.documentElement.scrollTop > 150)
      ) {
        header.classList.add('header--sticky');
      } else if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        header.classList.remove('header--sticky');
      } else {
        header.classList.remove('header--sticky');
      }
      prevScrollpos = currentScrollPos;
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        header.classList.add('header--sm-pd');
      } else {
        header.classList.remove('header--sm-pd');
      }
      if (toTop) {
        if (
          document.body.scrollTop > 500 ||
          document.documentElement.scrollTop > 500
        ) {
          toTop.classList.add('is-visible');
        } else {
          toTop.classList.remove('is-visible');
        }

        toTop.addEventListener('click', () => {
          animateScrollTo([0, 0]);
        });
      }

      if (scrollDown) {
        scrollDown.addEventListener('click', () => {
          animateScrollTo(
            document.querySelector(
              `[data-scrolltarget="${scrollDown.dataset.scrollto}"]`
            )
          );
        });
      }
    };
  }
};
