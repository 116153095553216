import Swiper, { Mousewheel } from 'swiper';

export default {
  init() {
    const sliders = document.querySelectorAll('.js-inner-article-slider');
    if (sliders.length > 0) {
      sliders.forEach(slider => {
        const swiper = new Swiper(slider as HTMLElement, {
          modules: [Mousewheel],
          loop: true,
          slidesPerView: 1.25,
          spaceBetween: 15,
          mousewheel: {
            forceToAxis: true
          },
          breakpoints: {
            769: {
              loop: false,
              slidesPerView: 1,
              spaceBetween: 49
            }
          }
        });
      });
    }
  }
};
