/* eslint-disable */

import Swiper, { Mousewheel } from 'swiper';

const $ = require('jquery');

export default {
  init() {
    const sliders = document.querySelectorAll('.js-activities-slider');
    let windowSize = 'desktop';

    if ($(window).width() < 769) {
      windowSize = 'mobile';
    } else {
      windowSize = 'desktop';
    }

    if (sliders.length > 0) {
      sliders.forEach(slider => {
        let actSwiper;

        if (windowSize === 'mobile') {
          actSwiper = new Swiper(slider, {
            modules: [Mousewheel],
            loop: true,
            slidesPerView: 1.5,
            spaceBetween: 15,
            mousewheel: {
              forceToAxis: true
            }
          });
        }

        const onResize = () => {
          if (actSwiper) {
            if ($(window).width() < 769) {
              if (windowSize === 'desktop') {
                windowSize = 'mobile';
                if (actSwiper != undefined) {
                  actSwiper.destroy();
                }
                actSwiper = new Swiper(slider, {
                  modules: [Mousewheel],
                  loop: true,
                  slidesPerView: 1.5,
                  spaceBetween: 15,
                  mousewheel: {
                    forceToAxis: true
                  }
                });
              }
            } else {
              windowSize = 'desktop';
              if (actSwiper != undefined) {
                actSwiper.destroy();
              }
            }
          }
        };

        window.addEventListener('resize', onResize);
      });
    }
  }
};
