import Swiper, { Mousewheel } from 'swiper';

export default {
  init() {
    const sliders = document.querySelectorAll('.js-news-slider');
    if (sliders.length > 0) {
      sliders.forEach(slider => {
        const swiper = new Swiper(slider, {
          modules: [Mousewheel],
          loop: true,
          slidesPerView: 1.25,
          spaceBetween: 15,
          mousewheel: {
            forceToAxis: true
          },
          breakpoints: {
            769: {
              loop: false,
              slidesPerView: 4,
              spaceBetween: 32
            }
          }
        });
      });
    }
  }
};
