export default {
  init() {
    const heroReadMore = document.querySelector('.js-h-block-button');

    if (heroReadMore) {
      heroReadMore.addEventListener('mouseenter', () => {
        heroReadMore.closest('.h-block').classList.add('is-hovered');
      });

      heroReadMore.addEventListener('mouseleave', () => {
        heroReadMore.closest('.h-block').classList.remove('is-hovered');
      });
    }
  }
};
