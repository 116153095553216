export default {
  init() {
    const drawerToggler = document.querySelector('.js-drawer-toggler');
    const drawer = document.querySelector('.js-drawer');
    const drawerSubTogglers = document.querySelectorAll(
      '.menu-item-has-children'
    );

    if (drawerToggler && drawer) {
      drawerToggler.addEventListener('click', () => {
        drawerToggler.classList.toggle('is-open');
        drawer.classList.toggle('is-open');
        document.body.classList.toggle('drawer-opened');
      });
    }

    if (drawerSubTogglers.length > 0) {
      const removeActive = (e: any) => {
        drawerSubTogglers.forEach(toggler => {
          if (
            e.currentTarget !== toggler &&
            toggler.classList.contains('is-open')
          ) {
            toggler.classList.remove('is-open');
            (toggler.nextElementSibling as HTMLElement).style.maxHeight = null;
          }
        });
      };

      drawerSubTogglers.forEach(toggler => {
        toggler.addEventListener(
          'click',
          (e: any) => {
            e.stopPropagation();
            if (e.target.tagName.toLowerCase() !== 'a') {
              removeActive(e);
              toggler.classList.toggle('is-open');
              const panel = toggler.querySelector('.sub-menu');
              if ((panel as HTMLElement).style.maxHeight) {
                (panel as HTMLElement).style.maxHeight = null;
              } else {
                (panel as HTMLElement).style.maxHeight = `${panel.scrollHeight}px`;
              }
            }
          },
          true
        );
      });
    }
  }
};
