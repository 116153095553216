export default {
  init() {
    const languageSwitch = document.querySelector('.js-lang-toggler');
    const languageDrop = document.querySelector('.js-lang-drop');
    const languageBackdrop = document.querySelector('.js-lang-backdrop');

    const dlanguageSwitch = document.querySelector('.js-drawer-lang-toggler');
    const dlanguageDrop = document.querySelector('.js-drawer-lang-drop');
    const dlanguageBackdrop = document.querySelector(
      '.js-drawer-lang-backdrop'
    );

    if (languageSwitch && languageDrop) {
      languageSwitch.addEventListener('click', () => {
        languageSwitch.classList.toggle('is-open');
        languageDrop.classList.toggle('is-open');
      });

      languageBackdrop.addEventListener('click', () => {
        languageSwitch.classList.remove('is-open');
        languageDrop.classList.remove('is-open');
      });
    }

    if (dlanguageSwitch && dlanguageDrop) {
      dlanguageSwitch.addEventListener('click', () => {
        dlanguageSwitch.classList.toggle('is-open');
        dlanguageDrop.classList.toggle('is-open');
      });

      dlanguageBackdrop.addEventListener('click', () => {
        dlanguageSwitch.classList.remove('is-open');
        dlanguageDrop.classList.remove('is-open');
      });
    }
  }
};
