import '../scss/style.scss';
import { nodeListForEach } from './addons/polyfills';
import StoriesSlider from './modules/StoriesSlider';
import InnerArticleSlider from './modules/InnerArticleSlider';
import ActivitiesSlider from './modules/ActivitiesSlider';
import CasesSlider from './modules/CasesSlider';
import NewsSlider from './modules/NewsSlider';
import CustomSelect from './modules/CustomSelect';
import LanguageSwitch from './modules/LanguageSwitch';
import HeroButtonHover from './modules/HeroButtonHover';
import FadeinAnim from './modules/FadeinAnim';
import Drawer from './modules/Drawer';
import Header from './modules/Header';
import Lity from './modules/Lity';
import LazyLoad from './modules/LazyLoad';

nodeListForEach();

const inits = (): void => {
  StoriesSlider.init();
  ActivitiesSlider.init();
  CasesSlider.init();
  NewsSlider.init();
  CustomSelect.init();
  LanguageSwitch.init();
  HeroButtonHover.init();
  FadeinAnim.init();
  Drawer.init();
  Header.init();
  Lity.init();
  LazyLoad.init();
  InnerArticleSlider.init();

  window.scrollTo(window.scrollX, window.scrollY - 1);
  window.scrollTo(window.scrollX, window.scrollY + 1);
};

const onScroll = (e: Event): void => {};

const onResize = (e: Event): void => {
  onScroll(e);
};

const onLoad = (e: Event): void => {
  inits();
  onResize(e);
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
