/* eslint-disable */

import Swiper, { EffectFade, Navigation, Mousewheel } from 'swiper';

const $ = require('jquery');

export default {
  init() {
    const casesSlider = document.querySelector('.js-cases-slider');
    let windowSize = 'desktop';
    let casesSwiper;

    if ($(window).width() < 769) {
      windowSize = 'mobile';
    } else {
      windowSize = 'desktop';
    }

    if (windowSize === 'desktop') {
      casesSwiper = new Swiper(casesSlider, {
        modules: [EffectFade, Navigation, Mousewheel],
        loop: true,
        effect: 'fade',
        speed: 500,
        fadeEffect: {
          crossFade: true
        },
        navigation: {
          nextEl: '.js-cases-hidden-next'
        }
      });
    } else {
      casesSwiper = new Swiper(casesSlider, {
        loop: true,
        slidesPerView: 1.25,
        spaceBetween: 15,
        mousewheel: {
          forceToAxis: true
        }
      });
    }

    const onResize = () => {
      if (casesSlider) {
        if ($(window).width() < 769) {
          if (windowSize === 'desktop') {
            windowSize = 'mobile';
            if (casesSwiper != undefined) {
              casesSwiper.destroy();
            }

            casesSwiper = new Swiper(casesSlider, {
              loop: true,
              slidesPerView: 1.25,
              spaceBetween: 15,
              mousewheel: {
                forceToAxis: true
              }
            });
          }
        } else {
          if (windowSize === 'mobile') {
            windowSize = 'desktop';
            if (casesSwiper != undefined) {
              casesSwiper.destroy();
            }
            casesSwiper = new Swiper(casesSlider, {
              modules: [EffectFade, Navigation, Mousewheel],
              loop: true,
              effect: 'fade',
              speed: 500,
              fadeEffect: {
                crossFade: true
              },
              navigation: {
                nextEl: '.js-cases-hidden-next'
              }
            });
          }
        }
      }
    };

    window.addEventListener('resize', onResize);

    $('body').on('click', '.js-next-case', e => {
      $('.js-cases-hidden-next').click();
    });
  }
};
