import inView from 'in-view';

export default {
  init() {
    inView('[data-animate-in]')
      .on('enter', el => {
        el.classList.add('visible');
      })
      .on('exit', el => {
        el.classList.remove('visible');
      });

    inView('.underline')
      .on('enter', el => {
        el.classList.add('visible');
      })
      .on('exit', el => {
        el.classList.remove('visible');
      });
  }
};
