import LazyLoad from 'vanilla-lazyload';

export default {
  init() {
    const myLazyLoad = new LazyLoad({
      callback_loaded: el => {
        (el as HTMLVideoElement).play();
      }
    });
    myLazyLoad.update();
  }
};
